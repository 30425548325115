<template>
    <styled-interface
        hide-toolbar
        no-padding>
        <onboarding-header>
            <template #actions>
                <action-button
                    v-if="agencyHasDealers"
                    icon="indicator-error"
                    position="left"
                    light
                    normal-text
                    @click="handleExit()">
                    Exit
                </action-button>
                <action-button
                    v-else
                    icon="lock"
                    position="left"
                    light
                    normal-text
                    @click="logout()">
                    Logout
                </action-button>
            </template>
        </onboarding-header>

        <div class="account-add layout">
            <styled-card
                :class="[
                    'mb-5',
                    userIsAdmin ? 'mt-4' : 'mt-5'
                ]"
                :width="1100"
                hide-header
                centered
                simple>
                <div class="">
                    <div class="layout">
                        <div class="place-search-container flex xs12 md7 pa-5">
                            <div class="mb-4">
                                <div class="text-xs-center mb-4">
                                    <h1>Welcome!</h1>
                                    <h3>Let's create a new account!</h3>
                                </div>
                                <p v-if="isNewOnboarding">
                                    Okay!  Nicely done!  Your BuyerBridge business is now in sync with your Facebook business manager and is ready for some accounts to be added.
                                </p>
                                <p v-else>
                                    Ready to onboard a new account? 🚀 Awesome! 🙌 You can expedite the process by searching for the associated business below or can enter the details manually if you can't find what you're looking for.
                                </p>
                            </div>
                            <div class="mb-4">
                                <place-search
                                    ref="placeSearch"
                                    class="mb-2"
                                    use-facebook-geocoder
                                    @input="onPlaceSelection"
                                    @error="onError" />
                                <div
                                    v-if="!showDetails"
                                    class="text-xs-right">
                                    <a
                                        href="#"
                                        @click.prevent="showDetails = true">
                                        ...or enter details manually
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="flex xs12 md5">
                            <img
                                class="banner"
                                src="/img/add-account-banner.svg">
                        </div>
                    </div>

                    <v-alert
                        :value="error"
                        type="error"
                        transition="fade-transition"
                        class="mx-5 mt-4">
                        <div v-html="error" />
                    </v-alert>

                    <div
                        v-if="showDetails"
                        class="pa-5">
                        <template v-if="hasPlace">
                            <h2>Please Verify These Details</h2>
                            <p>
                                Please verify that the information we retrieved is accurate including the map location.
                            </p>
                        </template>
                        <template v-else>
                            <h2>Please Enter Business Details</h2>
                            <p>
                                Please fill out the form below with the correct details for this account and ensure the map location is accurate.
                            </p>
                        </template>
                        <div class="layout wrap mb-4">
                            <manage-dealer
                                ref="manageDealer"
                                v-model="dealer"
                                :valid.sync="dealerIsValid"
                                class="flex xs12 md6 pa-2"
                                @new-address="onNewAddress"
                                @brand-asset-id-set="onBrandAssetIdSet" />
                            <div class="flex xs12 md6 pa-2 map-wrapper">
                                <map-marker-selector
                                    v-model="coordinates"
                                    :zoom="zoom"
                                    update-center />
                                <div class="map-info">
                                    Drag marker or click to adjust the location
                                </div>
                            </div>
                        </div>
                        <div class="text-xs-right">
                            <styled-button
                                :disabled="!dealerIsValid"
                                :loading="submitLoading"
                                @click="onSubmit">
                                CREATE ACCOUNT
                            </styled-button>
                        </div>
                    </div>
                </div>
            </styled-card>
        </div>
    </styled-interface>
</template>

<script>
import ActionButton from '@/components/globals/ActionButton';
import OnboardingHeader from '@/components/globals/OnboardingHeader';
import StyledInterface from '@/components/globals/StyledInterface';
import StyledCard from '@/components/globals/StyledCard';
import StyledButton from '@/components/globals/StyledButton';
import PlaceSearch from '@/components/globals/PlaceSearch';
import MapMarkerSelector from '@/components/globals/MapMarkerSelector';
import ManageDealer from '@/components/globals/ManageDealer';
import { GENERIC_SITE_PROVIDER } from '@/helpers/globals';
import { requiredRules } from '@/helpers/validationRules';
import { mapState, mapGetters,mapActions } from 'vuex';

export default {
    name: 'AccountAdd',
    title: 'Add New Account',
    components: {
        ActionButton,
        OnboardingHeader,
        MapMarkerSelector,
        PlaceSearch,
        StyledButton,
        StyledCard,
        StyledInterface,
        ManageDealer
    },
    data() {
        return {
            requiredRules,
            showDetails: false,
            hasPlace: false,
            address: null,
            coordinates: null,
            dealer: null,
            place: null,
            error: null,
            zoom: 1,
            submitLoading: false,
            dealerIsValid: false,
            isNewOnboarding: false,
            prevRoute: null,
            brandAssetId: null
        };
    },
    computed: {
        ...mapState({
            agency: (state) => state.agency.currentAgency
        }),
        ...mapGetters([
            'userIsAdmin',
            'agencyHasDealers'
        ]),
        isFirstRoute() {
            if (!this.prevRoute) {
                return true;
            }

            return (
                this.prevRoute.path == '/' ||
                this.prevRoute.name == null ||
                this.prevRoute.name == 'login'
            );
        }
    },
    created() {
        this.getDealerTimezones();
        this.isNewOnboarding = Object.prototype.hasOwnProperty.call(this.$route.query, 'new_onboarding');
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.prevRoute = from;
        });
    },
    methods: {
        ...mapActions([
            'getDealerTimezones',
            'updateCurrentDealer'
        ]),
        async onNewAddress(address) {

            // Retrieve the location from Facebook
            const location = await this.$apiRepository.getFacebookLocation(address.formatted);

            this.coordinates = {
                lat: location.position.lat,
                lng: location.position.lng
            };

            this.zoom = 15;
        },
        async onSubmit() {
            this.submitLoading = true;

            const dealerId = await this.createDealer();

            if (dealerId) {

                if (this.brandAssetId != null) {
                    const response = await this.$http.post('/brands', {
                        dealer_id: dealerId,
                        name: this.dealer.name + ' Account Brand',
                        public: true
                    });

                    const brandId = response.data.data.id;

                    await this.$http.post('/asset_brand', {
                        asset_id: this.brandAssetId,
                        brand_id: brandId,
                        asset_brand_type_id: 1
                    });

                    await this.$http.post('/brand_dealer', {
                        brand_id: brandId,
                        dealer_id: dealerId,
                        account_branding: true
                    });
                }

                // Now progress to the product selection page
                await this.$router.pushAsync({
                    name: 'channel-setup',
                    params: {
                        dealer_id: dealerId
                    }
                });

                this.reset();
            }

            this.submitLoading = false;
        },
        async createDealer() {
            try {
                this.error = null;

                const values = {
                    name: this.dealer.name,
                    agency_id: this.agency.id,
                    site_provider_id: GENERIC_SITE_PROVIDER,
                    vertical_id: this.dealer.vertical,
                    site_base_url: this.dealer.website,
                    phone: this.dealer.phone,
                    address: this.dealer.address.street,
                    city: this.dealer.address.city,
                    state: this.dealer.address.administrativeArea,
                    country_code: this.dealer.address.country,
                    zip: this.dealer.address.postalCode,
                    distance_unit: this.dealer.address.country == 'US' ? 'MI' : 'KM',
                    latitude: this.coordinates.lat,
                    longitude: this.coordinates.lng,
                    timezone_id:this.dealer.timezone_id,
                    configuration: {}
                };

                if (this.place) {
                    values.configuration.initial_place_id = (
                        this.place.placeId
                    );
                }

                if (this.dealer.verticalOther) {
                    values.configuration.vertical_other = (
                        this.dealer.verticalOther
                    );
                }

                const response = await this.$http.post('/dealers', values);

                return response.data.data.id;

            } catch (error) {
                console.error('Error creating dealer', error);

                let errorMsg = 'There was an error creating this account.  Please try again or <a href="mailto:support@buyerbridge.io">contact support</a> for further assistance.';
                if (error.response.data.error && error.response.data.error.messages) {
                    errorMsg = 'There were one or more errors while creating this account.  Please review them below and try again.  If you\'re still having issues please <a href="mailto:support@buyerbridge.io">contact support</a> for futher assistance.';
                    const messageLis = error.response.data.error.messages.map(message  => `<li>${message}</li>`);
                    errorMsg += `<br><br><ul>${messageLis}</ul>`;
                }

                this.error = errorMsg;
            }
        },
        onError(error) {
            this.error = error;
        },
        onPlaceSelection(place) {
            this.error = null;

            if (!place) {
                return;
            }

            this.coordinates = place.coordinates;
            this.zoom = 15;
            this.showDetails = true;
            this.hasPlace = true;
            this.place = { ...place };
            this.dealer = { ...place };
        },
        reset() {
            this.$refs.placeSearch.reset();
            this.showDetails = false;
            this.address = null;
            this.coordinates = null;
            this.dealer = null;
            this.place = null;
            this.error = null;
            this.hasPlace = false;
        },
        logout() {
            this.$router.push(
                {
                    name: 'login',
                },
                () => {
                    // Logout after navigation occurs to avoid errors
                    this.$store.dispatch('logout');
                }
            );
        },
        handleExit() {
            if (this.isFirstRoute) {
                this.$router.push({ name: 'dealers' });
            } else {
                this.$router.back();
            }
        },
        onBrandAssetIdSet(assetId) {
            this.brandAssetId = assetId;
        }
    }
};
</script>

<style lang="scss" scoped>
.account-add {
    margin-top: 160px;
}

.next-step {
    h2 {
        color: $gray-dark;
        font-size: 1.2rem;
    }
}

.next-step-solution {
    h3 {
        font-weight: bold;
        font-size: 1.5rem;
    }
    h4 {
        font-size: 1rem;
        color: $gray;
        font-style: italic;
    }
    ul {
        padding-left: 0;
        li {
            display: inline;
            margin-right: 10px;
            color: $blue-bg;
            &:before {
                content: '\2022';
                margin-right: 5px;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.place-search-container {
    h1 {
        font-size: 5rem;
        line-height: 7rem;
        color: $blue-btn;
    }
    h3 {
        font-weight: 600;
        color: $gray-dark;
    }
}

.banner {
    width: 100%;
    height: auto;
}

h3 {
    color: $blue-primary;
    font-weight: normal;
}

.action-description {
    margin-left: 33px;
}

.data-verification {
    span {
        &:first-child {
            color: $gray;
            text-decoration: line-through;
            padding-right: 5px;
        }
        &:last-child {
            padding-left: 5px;
        }
    }
}

.map-wrapper {
    margin-bottom: 60px;
}

.map-info {
    background-color: $bg-light-container;
    color: $gray;
    text-align: center;
    font-style: italic;
    padding: 10px;
}
</style>
