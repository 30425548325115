<template>
    <div
        class="header"
        :class="{
            'fixed': fixed
        }">
        <div class="header-steps">
            <div class="dealer">
                <div>
                    <div>
                        {{ dealer ? 'Onboarding for' : 'Onboarding' }}
                    </div>
                    <div v-if="dealer">
                        {{ dealer.name }}
                    </div>
                </div>
            </div>
            <div
                v-if="showProduct"
                class="product">
                <div>
                    <div>
                        Product Selection
                    </div>
                    <div v-if="products.length">
                        {{ productNames }}
                    </div>
                </div>
            </div>
            <div
                v-if="showPlay"
                class="play">
                <div>
                    <div>
                        Playbook Selection
                    </div>
                    <div v-if="play">
                        {{ play.display_name }}
                    </div>
                </div>
            </div>
            <div
                v-if="step"
                class="step">
                <div>
                    {{ step }}
                </div>
            </div>
        </div>
        <div class="header-actions">
            <styled-menu>
                <template #activator="{ on }">
                    <action-button
                        icon="life-buoy"
                        position="left"
                        light
                        normal-text
                        v-on="on">
                        Need help?
                    </action-button>
                </template>
                <a @click.prevent="$store.dispatch('createOnboardingSupportTicket')">
                    Open Ticket
                </a>
            </styled-menu>
            <span
                v-if="!!$slots['actions']"
                class="header-actions-separator" />
            <slot name="actions" />
        </div>
    </div>
</template>

<script>
import ActionButton from '@/components/globals/ActionButton';
import StyledMenu from '@/components/globals/StyledMenu';

export default {
    components: {
        ActionButton,
        StyledMenu
    },
    props: {
        dealer: {
            type: Object
        },
        showProduct: {
            type: Boolean,
            default: false
        },
        products: {
            type: Array,
            default() {
                return [];
            }
        },
        showPlay: {
            type: Boolean,
            default: false
        },
        play: {
            type: Object
        },
        step: {
            type: String
        },
        fixed: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            supportMenuOpen: false
        };
    },
    computed: {
        productNames() {
            return this.products.map(product => product.display_name).join(' and ');
        }
    }
};
</script>

<style lang="scss" scoped>
.header {
    background-color: $gray;
    color: $white;
    height: 60px;
    display: flex;
    &.fixed {
        position: fixed;
        left: 0;
        right: 0;
        z-index: 55;
    }
    & > div {
        height: 100%;
        display: flex;
        align-items: center;
    }
    .header-actions {
        margin-left: auto;
        padding: 0 20px;
    }
    .header-actions-separator {
        margin: 0 20px;
        background-color: $white;
        height: 30px;
        width: 1px;
    }
}
.header-steps {

    & > div {
        height: 100%;
        display: flex;
        align-items: center;
        position: relative;
        padding: 0 30px 0 50px;
        &:first-child {
            padding-left: 20px;
            background-color: #4A5256;
            &::after {
                border-left: 20px solid #4A5256;
                z-index: 5;
            }
        }
        &:nth-child(2) {
            background-color: #636D73;
            &::after {
                border-left: 20px solid #636D73;
                z-index: 4;
            }
        }
        &:nth-child(3) {
            background-color: #818E95;
            &::after {
                border-left: 20px solid #818E95;
                z-index: 3;
            }
        }
        &::after {
            content: '';
            position: absolute;
            right: -20px;
            display: block;
            width: 0;
            height: 0;
            border-top: 30px solid transparent;
            border-bottom: 30px solid transparent;
        }
        & > div > div:nth-child(2) {
            font-weight: bold;
        }
    }
}
</style>