<template>
    <div>
        <div
            v-if="loading"
            class="pa-5 text-xs-center">
            <loader />
        </div>
        <v-form
            v-else
            ref="form"
            :class="{
                'location-only': locationOnly
            }">
            <v-select
                ref="verticalField"
                v-model="vertical"
                :rules="requiredRules"
                :items="industries"
                item-text="display_name"
                item-value="id"
                label="Industry"
                class="styled-field mb-3 vertical-field" />
            <v-text-field
                v-if="vertical === VERTICAL_OTHER"
                ref="verticalOtherField"
                v-model="verticalOther"
                label="Other Vertical"
                :rules="requiredRules"
                class="styled-field mb-3 vertical-other-field" />
            <v-text-field
                ref="nameField"
                v-model="name"
                label="Name"
                :rules="requiredRules"
                class="styled-field mb-3 name-field" />
            <v-autocomplete
                ref="timezone"
                v-model="selectedTimezone"
                :rules="requiredRules"
                :items="dealerTimezones"
                item-text="google_id"
                item-value="id"
                label="Timezone"
                class="styled-field mb-3 timezone-field" />
            <v-select
                ref="countryField"
                v-model="country"
                :rules="requiredRules"
                :items="countries"
                item-text="name"
                item-value="abbreviation"
                label="Country"
                class="styled-field mb-3"
                return-object />
            <template v-if="country">
                <v-text-field
                    ref="streetField"
                    v-model="street"
                    :label="addressLabel"
                    :rules="requiredRules"
                    class="styled-field mb-3" />
                <v-text-field
                    ref="cityField"
                    v-model="city"
                    label="City"
                    :rules="localityRules"
                    class="styled-field mb-3" />
                <div class="layout wrap">
                    <v-autocomplete
                        ref="administrativeAreaField"
                        v-model="administrativeArea"
                        :label="administrativeAreaLabel"
                        :rules="requiredRules"
                        :items="administrativeAreas"
                        item-text="name"
                        item-value="value"
                        :no-data-text="`No matching ${administrativeAreaLabel} found...`"
                        class="styled-field flex xs12 md6 mb-3" />
                    <v-text-field
                        ref="postalCodeField"
                        v-model="postalCode"
                        :label="postalLabel"
                        :rules="[...requiredRules, ...postalRules]"
                        class="styled-field flex xs12 md5 offset-md1 mb-3" />
                </div>
                <v-text-field
                    ref="phoneField"
                    v-model="phone"
                    label="Phone"
                    :rules="[...requiredRules, ...phoneRules]"
                    :hint="`Enter phone number as ${phoneFormats}`"
                    class="styled-field mb-3 phone-field" />
                <v-text-field
                    ref="websiteField"
                    v-model="website"
                    label="Website URL"
                    :rules="[ ...requiredRules, ...urlRules ]"
                    class="styled-field mb-3 website-field" />
            </template>
            <asset-upload
                title="Upload a Logo (optional)"
                :types="['jpeg', 'jpg', 'png', 'gif']"
                :records="['dealer_id', 'agency_id']"
                @asset-uploaded="onAssetUploaded" />
        </v-form>
    </div>
</template>

<script>
import Loader from '@/components/globals/Loader';
import countryAreas from '@/helpers/countries';
import isEqual from '@/helpers/isEqual';
import validationRules from '@/helpers/validationRules';
import { get } from 'lodash';
import { mapState, mapActions } from 'vuex';
import { VERTICAL_OTHER } from '@/helpers/globals';
import AssetUpload from '@/components/globals/AssetUpload';

export default {
    components: {
        AssetUpload,
        Loader
    },
    props: {
        value: {
            type: Object,
            default: null
        },
        address: {
            type: Object,
            default: null
        },
        valid: {
            type: Boolean,
            default: false
        },
        locationOnly: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            // Validation
            requiredRules: validationRules.requiredRules,
            nameRules: validationRules.nameRules,
            emailRules: validationRules.emailRules,
            urlRules: validationRules.urlRules,
            phoneRules: [],
            localityRules: [],
            postalRules: [],

            // Dynamic labels
            addressLabel: '',
            administrativeAreaLabel: '',
            localityLabel: '',
            postalLabel: '',
            phoneFormats: '',

            // Data
            loading: false,
            isNewAddress: false,
            countries: [],
            industries: [],
            VERTICAL_OTHER,

            // Dealer data
            vertical: '',
            verticalOther: '',
            name: '',
            street: '',
            city: '',
            administrativeArea: '',
            postalCode: '',
            country: '',
            website: '',
            phone: '',
            timezones:[],
            selectedTimezone:null,
            brandAssetId:null,

        };
    },
    computed: {
        administrativeAreas() {
            if (!this.country) {
                return [];
            }

            return this.country.areas.map(area => {

                let name = area.name;
                // Append the abbreviation if it's set
                if (area.abbreviation) {
                    name += ` (${area.abbreviation})`;
                }

                return {
                    name,
                    value: area.abbreviation || area.name
                };
            });
        },
        formattedAddress() {
            if (!this.street ||
                !this.city ||
                !this.administrativeArea ||
                !this.postalCode ||
                !this.country) {
                return null;
            }

            // Validate all address fields
            if (
                !get(this.$refs,'streetField.valid') ||
                !get(this.$refs,'cityField.valid') ||
                !get(this.$refs,'administrativeAreaField.valid') ||
                !get(this.$refs,'postalCodeField.valid') ||
                !get(this.$refs,'countryField.valid')
            ) {
                return null;
            }

            // Maintain whether the address is new
            // for external subscribers
            if (!this.isNewAddress) {
                /* eslint-disable-next-line */
                this.isNewAddress = true;
            }

            return {
                street: this.street,
                city: this.city,
                administrativeArea: this.administrativeArea,
                postalCode: this.postalCode,
                country: this.country.abbreviation,
                formatted: this.getAddressString()
            };
        },
        formattedDealer() {

            if (!this.street ||
                !this.city ||
                !this.administrativeArea ||
                !this.postalCode ||
                !this.country ||
                !this.vertical ||
                !(this.vertical === VERTICAL_OTHER ? this.verticalOther : true) ||
                !this.name ||
                !this.website ||
                !this.selectedTimezone ||
                !this.phone) {
                return null;
            }

            // Validate all fields
            if (
                !get(this.$refs,'streetField.valid') ||
                !get(this.$refs,'cityField.valid') ||
                !get(this.$refs,'administrativeAreaField.valid') ||
                !get(this.$refs,'postalCodeField.valid') ||
                !get(this.$refs,'countryField.valid') ||
                !get(this.$refs,'verticalField.valid') ||
                !(this.vertical === VERTICAL_OTHER ? get(this.$refs,'verticalOtherField.valid') : true) ||
                !get(this.$refs,'nameField.valid') ||
                !get(this.$refs,'websiteField.valid') ||
                !get(this.$refs,'phoneField.valid') ||
                !get(this.$refs,'timezone.valid')
            ) {
                return null;
            }

            return {
                vertical: this.vertical,
                verticalOther: this.verticalOther,
                timezone_id:this.selectedTimezone,
                name: this.name,
                phone: this.phone,
                website: this.website,
                address: {
                    street: this.street,
                    city: this.city,
                    administrativeArea: this.administrativeArea,
                    postalCode: this.postalCode,
                    country: this.country.abbreviation,
                    formatted: this.getAddressString()
                }
            };
        },
         ...mapState({
            dealerTimezones: (state) => state.dealer.timezones
        }),
    },
    watch: {
        value(value) {
            this.setData(value);
        },
        isNewAddress(value, oldValue) {

            // Emit an event when we transition to a new address
            // which is triggered the first time the user validates
            // an address.  This is generally used for geocoding
            // externally
            if (value === true && oldValue === false) {
                this.$emit('new-address', this.formattedAddress);
            }
        },
        formattedAddress(address) {
            if (address !== null) {
                this.$emit('change-address', address);
            }
        },
        formattedDealer(dealer) {

            this.$emit('update:valid', dealer !== null);

            if (dealer !== null) {
                this.$emit('input', this.formattedDealer);
            }
        },
        country() {
            if (!this.country) {
                return;
            }

            // Enure we're using the appropriate rules for input
            this.phoneRules = validationRules.phoneRules(this.country.abbreviation);
            this.areaCodeRules = validationRules.areaCodeRules(this.country.abbreviation);
            this.localityRules = validationRules.localityRules(this.country.abbreviation);
            this.postalRules = validationRules.postalRules(this.country.abbreviation);
            this.currencyRules = validationRules.currencyRules(this.country.abbreviation);

            // Ensure labels are translated correctly
            this.addressLabel = this.country.translations.address;
            this.administrativeAreaLabel = this.country.translations.administrativeArea;
            this.localityLabel = this.country.translations.locality;
            this.postalLabel = this.country.translations.postal;
            this.phoneFormats = this.country.translations.phoneFormats;
        },

    },
    async mounted() {
        if (!this.locationOnly) {
            await this.loadVerticals();
        }

        this.countries = countryAreas;
        if (this.value) {
            this.setData(this.value);
        }
    },
    methods: {
        ...mapActions(['getDealerTimezones']),
        getAddressString() {
            return `${this.street}, ${this.city}, ${this.administrativeArea}, ${this.postalCode}, ${this.country.name}`;
        },
        async setData(value) {

            if (typeof value !== 'object' ||
                isEqual(this.formattedDealer, value)) {
                return;
            }

            this.country = this.countries.find(country => country.abbreviation == value.address.country);

            await this.$nextTick();

            this.vertical = value.vertical;
            this.verticalOther = value.verticalOther;
            this.name = value.name;
            this.phone = value.phone;
            this.website = value.website;
            this.street = value.address.street;
            this.city = value.address.city;
            this.administrativeArea = value.address.administrativeArea;
            this.postalCode = value.address.postalCode;
            this.selectedTimezone = value.timezone_id;



            // Force a validation event to get computed fields to trigger
            this.$refs.form.validate();
        },
        async reset() {
            this.isNewAddress = false;
            this.country = '';

            await this.$nextTick();

            this.vertical = '';
            this.verticalOther = '';
            this.name = '';
            this.phone = '';
            this.website = '';
            this.street = '';
            this.city = '';
            this.administrativeArea = '';
            this.postalCode = '';
            this.selectedTimezone = '';

            // Report back to the parent
            this.$emit('update:valid', false);
            this.$emit('input', null);
        },
        async loadVerticals() {
            try {
                this.loading = true;

                const response = await this.$apiRepository.getVerticals();

                this.industries = response.data.data;

            } catch(error) {
                console.log('Error loading verticals', error.response || error);
            } finally {
                this.loading = false;
            }

        },
        async onAssetUploaded(assetId) {
            this.brandAssetId = assetId;
            this.$emit('brand-asset-id-set', assetId);
        }
    }
};
</script>

<style lang="scss" scoped>

/* Just hide the fields to alleviate complicating code */
.location-only {
    .timezone-field,
    .vertical-field,
    .vertical-other-field,
    .name-field,
    .website-field,
    .phone-field {
        display: none;
    }
}
</style>
